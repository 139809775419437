<template>
    <div class="manager-layout">
        <ManagerSidebar @toogle-sidebar="toogleSidebar" />

        <div class="manager-content-wrapper">
            <slot />

            <ModalsContainer />
        </div>
    </div>
</template>

<script setup lang="ts">
import {ModalsContainer} from 'vue-final-modal'
import ManagerSidebar from "~/components/manager/ManagerSidebar.vue";

useHead({
    htmlAttrs: {
        // class: 'dark'
    }
});

const toogleCookie = useCookie('ManagerSidebarToogle');
const sidebarWidth = ref(toogleCookie.value ? '65px' : '240px');
const toogleSidebar = () => {
    sidebarWidth.value = sidebarWidth.value === '240px' ? '65px' : '240px';
}
</script>


<style lang="scss">
.manager-body {
    background-color: var(--main_bgc);

    .manager-layout {
        display: flex;
        overflow: hidden;
        flex-direction: row;
        min-height: 100vmin;

        .manager-sidebar-wrapper {
            width: v-bind('sidebarWidth');
            min-width: v-bind('sidebarWidth');

            .manager-sidebar {
                width: v-bind('sidebarWidth');
                min-width: v-bind('sidebarWidth');
            }
        }

        .manager-content-wrapper {
            display: flex;
            padding: 15px 15px 10px 5px;
            flex-direction: column;
            width: calc(100vw - v-bind('sidebarWidth'));

            .manager-page {
                overflow: hidden;
                border-radius: 15px;
                background-color: #2c2c2c;
                min-height: calc(100vh - 35px);

                .manager-page-divider {
                    margin: 0 20px;
                    width: calc(100% - 40px);
                    border-bottom: 1px solid var(--main_border-color);
                }
            }
        }
    }
}
</style>
