<template>
    <div class="manager-sidebar-wrapper">
        <aside class="manager-sidebar" :class="{collapsed: collapsed}">
            <div class="main-content">
                <NuxtLink to="/" class="sidebar-header">
                    <NuxtImg src="/img/tray.svg" width="25px" class="mr-2" />
                    <span v-if="!collapsed" class="dark:text-white ff-raleway app-name">OnLine.Menu</span>
                </NuxtLink>

                <div class="sidebar-content">
                    <div
                            :key="link.to"
                            v-for="link in links"
                            class="sidebar-link mb-1"
                            :class="{active: route.path === link.to}"
                    >
                        <NuxtLink :to="link.to" class="dark:text-white">
                            <Icon :name="link.icon" color="#fff" size="19px" class="mr-2 mt-0.5" />
                            <span v-if="!collapsed">
                                {{ link.title }}
                            </span>
                        </NuxtLink>

                        <div v-show="route.path === link.to" class="active-link-angle active-top-angle"></div>
                        <div v-show="route.path === link.to" class="active-link-angle active-bottom-angle"></div>
                    </div>
                </div>
            </div>

            <div class="sidebar-footer">
                <div class="sf-user">
                    <div class="sf-user__avatar"></div>
                    <div v-if="!collapsed" class="sf-user__info">
                        <span class="sf-user__name mb-1">
                            {{ user.firstname }} {{ user.lastname }}
                        </span>

                        <span class="sf-user__email text-slate-500">
                            {{ user.email }}
                        </span>
                    </div>

                    <button v-if="!collapsed" class="sf-user__logout" @click="() => user.logout()">
                        <Icon name="mingcute:power-line" color="darkgrey" size="18px" />
                    </button>
                </div>

                <div v-if="!isMobile" class="sf-collapse pointer" @click="toogleSidebar">
                    <template v-if="!collapsed">
                        <Icon name="mingcute:arrow-left-circle-line" color="#fff" class="mr-1.5" />
                        Collapse menu
                    </template>
                    <template v-else>
                        <Icon name="mingcute:arrow-right-circle-line" color="#fff" />
                    </template>
                </div>
                <div v-else class="sf-collapse pointer">
                    <button class="sf-user__logout" @click="() => user.logout()">
                        <Icon name="mingcute:power-line" color="darkgrey" size="18px" />
                    </button>
                </div>
            </div>
        </aside>
    </div>
</template>

<script setup lang="ts">
import useScreenSize from "~/mixins/ScreenSize";

const route = useRoute();
const toogleCookie = useCookie('ManagerSidebarToogle');

const user = useUserStore();
const collapsed = ref(toogleCookie.value || false);
const {isMobile} = useScreenSize();

const emit = defineEmits(['toogleSidebar']);
const toogleSidebar = () => {
    collapsed.value = !collapsed.value;
    emit('toogleSidebar');
    toogleCookie.value = `${collapsed.value}`;
}

const links = [
    {
        icon: 'mingcute:book-5-line',
        title: 'Menus',
        to: '/manager',
    },
    {
        icon: 'mingcute:process-line',
        title: 'Projects',
        to: '/manager/projects',
    },
    {
        icon: 'mingcute:contacts-2-line',
        title: 'Accaunt',
        to: '/manager/account',
    },
];

watch(isMobile, async (newIsMobile, oldIsMobile) => {
    if (newIsMobile && !collapsed.value) {
        collapsed.value = true;
        emit('toogleSidebar');
        toogleCookie.value = `${collapsed.value}`;
    }
});

</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';

.manager-sidebar-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.manager-sidebar {
    display: flex;
    height: 100vh;
    position: fixed;
    flex-direction: column;
    padding: 10px 12.5px 15px;
    background-color: transparent;
    justify-content: space-between;
}

.sidebar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 7.5px;
    border-bottom: 1px solid var(--main_border-color);

    .app-name {
        @include font-size(20px);
        @include line-height(20px);
    }
}

.sidebar-content {
    padding: 15px 0;

    .sidebar-link {
        height: 40px;
        position: relative;
        border-radius: 10px;

        a {
            width: 100%;
            height: 40px;
            padding: 7.5px 10px;
            align-items: center;
            display: inline-flex;

            span {
                @include font-size(16px);
                @include line-height(14px);
            }
        }

        &.active {
            background-color: #2c2c2c;
            width: calc(100% + 17.5px);
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;

            .active-link-angle {
                width: 0;
                right: 0;
                height: 0;
                position: absolute;
                border-style: solid;

            }

            .active-top-angle {
                top: -12.5px;
                border-width: 0 0 12.5px 12.5px;
                border-color: transparent transparent #2c2c2c transparent;

                &::before {
                    top: -2px;
                    left: -18px;
                    content: "";
                    width: 19px;
                    height: 12.5px;
                    border-radius: 50%;
                    position: absolute;
                    transform: rotateZ(-45deg);
                    background-color: var(--main_bgc);
                }
            }

            .active-bottom-angle {
                bottom: -12px;
                border-width: 0 12.5px 12.5px 0;
                border-color: transparent #2c2c2c transparent transparent;

                &::before {
                    top: 2px;
                    left: -6px;
                    content: "";
                    width: 19px;
                    height: 12.5px;
                    border-radius: 50%;
                    position: absolute;
                    transform: rotateZ(45deg);
                    background-color: var(--main_bgc);
                }
            }

            &:before {
                top: 0;
                right: 0;
                content: '';
                position: absolute;
            }
        }
    }
}

.sidebar-footer {
    display: flex;
    flex-direction: column;

    .sf-user {
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 7.5px 10px 2.5px;
        border-top: 1px solid var(--main_border-color);
        border-bottom: 1px solid var(--main_border-color);

        .sf-user__avatar {
            width: 34px;
            height: 34px;
            min-width: 34px;
            min-height: 34px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(/img/default-avatar.webp);
        }

        .sf-user__info {
            display: flex;
            overflow: hidden;
            user-select: none;
            padding-left: 6px;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 34px - 18px);

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .sf-user__name {
                color: var(--main_color);
                @include font-size(16px);
                @include line-height(16px);
            }

            .sf-user__email {
                @include font-size(12px);
                @include line-height(12px);
            }
        }

        .sf-user__logout {

        }
    }

    .sf-collapse {
        height: 44px;
        display: flex;
        user-select: none;
        align-items: center;
        color: var(--main_color);
        padding: 10px 7.5px 10px 11.5px;

        svg {
            font-size: 17px;
            margin-right: 8px;
        }
    }
}

.manager-sidebar.collapsed {
    .sidebar-header, .sidebar-link a, .sf-user, .sf-collapse {
        padding-right: 0;
    }

    .sidebar-header img, .sf-collapse svg {
        margin-right: 0;
    }
}
</style>
